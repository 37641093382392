/**
 * UI Initiative Shutters Slider
 *
 * Infinite 3D carousel slider
 *
 * https://uiinitiative.com
 *
 * Copyright 2023 UI Initiative
 *
 * Released under the UI Initiative Regular License
 *
 * October 25, 2023
 */

.swiper-shutters .swiper-slide,
.swiper-shutters swiper-slide {
  overflow: hidden;
}
.swiper-shutters-image, .swiper-shutters-image-clones, .swiper-shutters-image-clone {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.swiper-shutters-image {
  object-fit: cover;
  object-position: center;
}
.swiper-shutters-image-clone {
  overflow: hidden;
}