/**
 * UI Initiative Swiper GL
 *
 * Stunning WebGL image transitions
 *
 * https://uiinitiative.com
 *
 * Copyright 2024 UI Initiative
 *
 * Released under the UI Initiative Regular License
 *
 * April 25, 2024
 */

.swiper-gl {
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
}
.swiper-gl .swiper-slide,
.swiper-gl swiper-slide {
  position: relative;
}
.swiper-gl .swiper-gl-image {
  display: none;
}
.swiper-gl > canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.swiper-gl-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  left: 0;
  top: 0;
}